(function(window, document, __tnt) {

    var me = __tnt.eedition || (__tnt.eedition = {}),
        windowHeight = window.innerHeight,
        windowWidth = window.innerWidth,
        UUID = document.getElementById('eedition-data').getAttribute('data-eedition-uuid'),
        viewMode = me.utilities.viewMode(UUID),
        libsInitialized = false;

    var initEvents = function(e) {
        if (e.type === 'DOMContentLoaded') {
            if (me.browser.isIos()) {
                $('#eedition-wrapper').addClass('ios');
                $('.eedition-zoom').hide();
            }
            me.menu.init();

            /* Determine if landscape or portrait and view mode */
            if (me.utilities.hasSegments() && $(window).height() >= $(window).width()) {
                $('#eedition-page-container').removeClass('mode-seg').addClass('mode-map');
            } else {
                $('#eedition-page-container').removeClass('mode-map').removeClass('mode-seg');
            }
            $('#eedition-page-container').addClass('view-'+viewMode);

            if (me.subscription.hasServices()) {
                if (typeof __tnt.subscription !== 'undefined' && typeof __tnt.subscription.access !== 'undefined') {
                    __tnt.subscription.access.status.check(function(e) {
                        if (e.hasAccess) initLibs();
                    });
                }
            } else {
                initLibs();
            }
            me.utilities.pageview();
            
            document.querySelectorAll('[data-target="#modal-search"]').forEach(function(trigger) {
                trigger.addEventListener('click', function() {
                    var seachHolder = document.getElementById('search-holder');
                    if (seachHolder.dataset.populated == 'false') {
                        seachHolder.src = __tnt.scrubUrl(document.getElementById('modal-search').dataset.searchUrl);
                        seachHolder.dataset.populated = 'true';
                    }
                });
            });
        } else if (e.type === 'tnt-user-status') {
            /** Support for custom Lee subscription. */
            if (typeof __tnt.subscription === 'undefined' && e.hasAccess) initLibs();
        } else if (e.type === 'load') {
            /** Refresh page on popstate to prevent stale content when navigating using the back and forward buttons.
            Popstate event listener is wrapped in a onload event to resolve Safari 8 bug. */
            setTimeout(function() {
                window.addEventListener('popstate', function() {
                    location.reload(true);
                });
            }, 0);
        } else if (e.type === 'orientationchange') {
            setTimeout(function() {
                me.resize();
                if (me.utilities.hasSegments()) {
                    me.imageMap.init();
                    if (me.iscroll.map) me.iscroll.map.refresh();
                }
                me.iscroll.init();
                if (me.iscroll.segments) me.iscroll.segments.refresh();
            }, 250);
        } else if (e.type === 'resize' || e.type === 'visibilitychange') {
            if (window.innerWidth != windowWidth || window.innerHeight != windowHeight) {
                me.resize();
                if (me.utilities.hasSegments()) me.imageMap.init();
                windowHeight = window.innerHeight;
                windowWidth = window.innerWidth;
            }
        }
    };

    var initPageAdObserver = function() {
        var pageTopEl = document.getElementById('eedition-page-top');
        var pageBottomEl = document.getElementById('eedition-page-bottom');
        if (pageTopEl || pageBottomEl) {
            var resizePage = function() {
                me.resize();
                if (viewMode == 'image' && me.iscroll.segments) {
                    me.iscroll.segments.refresh();
                }
            };
            var observer = new MutationObserver(function(mutations, observer) {
                var hasMutated = false;
                for (var i = 0; i < mutations.length; i++) {
                    if (mutations[i].type === 'childList' || (mutations[i].type === 'attributes' && mutations[i].target != pageTopEl && mutations[i].target != pageBottomEl)) {
                        hasMutated = true;
                    }
                    if (mutations[i].addedNodes.length > 0) {
                        var adImg = mutations[i].addedNodes[0].tagName == 'IMG' ? mutations[i].addedNodes[0]
                            : mutations[i].addedNodes[0].childNodes.length > 0 && mutations[i].addedNodes[0].childNodes[0].tagName == 'IMG' ? mutations[i].addedNodes[0].childNodes[0]
                            : null;
                        if (adImg) {
                            adImg.onload = resizePage;
                        }
                    }
                }
                if (hasMutated) {
                    resizePage();
                }
            });
            if (pageTopEl) {
                observer.observe(pageTopEl, {attributeFilter:['style'], childList:true, subtree:true});
            }
            if (pageBottomEl) {
                observer.observe(pageBottomEl, {attributeFilter:['style'], childList:true, subtree:true});
            }
        }
    };

    var initLibs = function() {
        if (libsInitialized) return;
        me.navigation.init();
        me.image.load(viewMode);
        if (me.utilities.hasSegments()) {
            me.cutouts.init();
            me.imageMap.init();
            me.keyboard.init();
            me.print.init();
        }
        me.mode(viewMode);
        me.intro.init();
        libsInitialized = true;
    };

    window.addEventListener('DOMContentLoaded', initEvents, false);
    window.addEventListener('load', initEvents, false);
    window.addEventListener('orientationchange', initEvents, false);
    window.addEventListener('resize', initEvents, false);
    window.addEventListener('tnt-user-status', initEvents, false);
    window.addEventListener('visibilitychange', initEvents, false);
    initPageAdObserver();
})(window, document, __tnt);